<template>
  <loading-screen v-show="isVisible" :content="loadingScreenContent" />
</template>

<script>
import Vue from 'vue';
import router from '../../router/index';
import LoadingScreen from '../../components/LoadingScreen.vue';

export default {
  name: 'VerifyEmail',

  components: {
    LoadingScreen,
  },

  data() {
    return {
      loadingScreenContent: {
        title: {
          text: 'Loading user',
          class: ['egx-partner-color'],
          style: 'color: var(--egx-partner-color)',
        },
        description: {
          text: 'Please wait while we load the user profile',
          class: [],
          style: {},
        },
        spinner: {
          class: ['egx-partner-color'],
          style: {},
        },
        cta: {
          text: 'If this page doesn\'t refresh, please click here',
          class: ['egx-partner-color'],
          style: {},
          path_name: 'sign-in',
        },
      },
      isVisible: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    Vue.loginAsToken = to.query.token;

    next();
  },

  mounted() {
    this.beginLoginAs();
  },

  computed: {
    isSSOSignIn() {
      return this.$store.state.users.currentUser.third_party_signup;
    },

    sso() {
      return this.$store.state.partners.currentPartner.sso;
    },

    ssoLandingPage() {
      return this.sso.login_landing_page;
    },

    currentUser() {
      return this.$store.state.users.currentUser;
    },
  },

  methods: {
    beginLoginAs() {
      this.isVisible = true;
      const tokenParams = { token: Vue.loginAsToken };

      const promise = new Promise((resolve) => {
        resolve(this.$store.dispatch('/api/v1/partners'));
      }).then(() => {
        console.log('Clean auth');
        return this.$store.dispatch('auth/clean');
      }).then(() => {
        console.log('Login with Token');
        return this.$store.dispatch('auth/emailVerification', tokenParams)
          .then(() => {
            if (this.currentUser.extra_info.verification_step === 'email_search') {
              router.push({ name: 'fetch-account-details' });
            } else if (this.currentUser.extra_info.verification_step === 'detail_search') {
              router.push({ name: 'UserEdit' });
            } else {
              this.$store.dispatch('buildings/getAll')
                .then(() => {
                  router.push({ name: this.loginLandingPage().toString() });
                });
            }
          });
      });

      Promise.all([promise])
        .catch((error) => {
          console.log(error);
          this.isVisible = false;
          this.$notify({
            group: 'notice',
            text: error.response.data.error,
            duration: 5000,
          });
          Vue.loginAsToken = '';
          router.push({ name: 'sign-in' });
        });
    },

    loginLandingPage() {
      if (this.isSSOSignIn && this.sso && this.ssoLandingPage) {
        return this.ssoLandingPage;
      }

      if (this.isSSOSignIn) {
        return 'UserEdit';
      }

      return 'buildings-list';
    },
  },
};
</script>
