<template>
  <div class="prq-section col-12 p-4">
    <div class="content-box row">
      <div class="col-12">
        <header>
          <h2 class="mt-3 title">
            <span class="egx-partner-color">
              {{stepConfiguration.title}} Not Configured
            </span>
          </h2>
          <p class="sub-title">
            No {{ stepConfiguration.title }} are currently available for this program application. Please check your configuration settings or contact your administrator to set up the required {{ stepConfiguration.title }}.
          </p>
        </header>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NoConfiguredPRQs',

  props: {
    stepConfiguration: Object,
  },

  computed: {
    isPrq() {
      return this.stepConfiguration.question_type === 'Question';
    },
    isAttachment() {
      return this.stepConfiguration.question_type === 'Attachment';
    },
    fetchedData() {
      console.log(this.stepConfiguration)
      return this.stepConfiguration.questions;
    },
  },
}; 
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />
<style lang="scss" scoped>
@import '../../assets/stylesheets/components/project/prqs.scss';
</style>
