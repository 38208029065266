<template>
  <loading-screen v-show="isVisible" :content="loadingScreenContent" />
</template>

<script>
import LoadingScreen from '../components/LoadingScreen.vue';

export default {
  name: 'ApplicationsNew',

  components: {
    LoadingScreen,
  },

  data() {
    return {
      loadingScreenContent: {
        title: {
          text: 'Creating a New Program Application',
          class: ['egx-partner-color'],
          style: 'color: var(--egx-partner-color)',
        },
        description: {
          text: 'Please wait while we create an application for the program you selected',
          class: [],
          style: {},
        },
        spinner: {
          class: ['egx-partner-color'],
          style: {},
        },
        cta: {
          text: 'If this page doesn\'t refresh, please click here',
          class: ['egx-partner-color'],
          style: {},
          path_name: 'applicationsShowAll',
        },
      },
      isVisible: false,
    };
  },

  mounted() {
    this.beginCreateProject()
  },

  computed: {
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },
  },

  methods: {
    beginCreateProject() {
      this.isVisible = true;
      console.log('beginCreateProject');
      this.$store.dispatch('fulfill_integrate/listOrSelectBuilding')
        .then((response) => {
          if(response.building_id || this.currentBuilding.selectedId) {
            const buildingId = response.building_id || this.currentBuilding.selectedId;
            const programId = this.$route.query.programId;
            const params = {
              building_id: buildingId,
              program_id: programId,
            };
            this.$store.dispatch('fulfill_integrate/createProjects', params)
              .then((response) => {
                this.$notify({
                  group: 'notice',
                  text: response.message,
                  duration: 5000,
                });
                if(this.$store.state.users.currentUser.extra_info.programId) {
                  this.$store.dispatch('users/get').then(() => {
                    console.log("user Updated");
                  });
                }
                this.isVisible = false;
                this.$router.push({ path: `/applications/${response.project_id}/edit` });
              })
              .catch((error) => {
                console.log(error);
                this.isVisible = false;
                this.$router.push({ name: 'applicationsShowAll' });
                this.$notify({
                  group: 'notice',
                  text: error.error,
                  duration: 5000,
                });
              });
          } else {
            this.isVisible = false;
            this.$router.push({ path: `/buildings`, query: { programId: this.$route.query.programId } });
          }
        })
        .catch((error) => {
          console.log(error);
          this.isVisible = false;
          this.$router.push({ name: 'applicationsShowAll' });
          this.$notify({
            group: 'notice',
            text: error.error,
            duration: 5000,
          });
        });
    }
  }
};
</script>
