<template>
  <div :class="[{'content-box': !disableCard}, { 'no-card': disableCard }]">
    <div class="m-2 text-center" v-show="isLoading">
      <font-awesome-icon
        :icon="['fa', 'circle-notch']"
        class="fa-spin m-auto spinner fa-5x egx-partner-color"
      />
    </div>
    <div :class="['card-body', { 'no-card': disableCard }]" v-if="!isLoading">
      <span class="egx-partner-color">
        <h2 v-html="stepTitle" />
      </span>
      <div v-html="stepConfiguration.main_instructions" v-if="!this.queryHideInst" />
      <component
        :is="getComponent2(queryStepType)"
        :content="content"
        :navigate="navigateNextStep"
        :project-id="projectId"
        :project-status="projectStatus"
        :steps-response="stepsResponse"
        :step-configuration="stepConfiguration"
        :key="queryStepNumber"
        :fulfill-sync-details="fulfillSyncDetails"
        @selected-contact-id="storeSelectedContact"
      />
      <step-button
        :steps-response="stepsResponse"
        @navigate="navigateNextStep"
        @save-data="saveContact"
        :data-save-required="true"
        v-if="showFooterButton"
      />
    </div>
  </div>
</template>

<script>
import Contact from './Contact.vue';
import DetailSection from './project/DetailSection.vue';
import StepButton from './project/stepButton.vue';
import redirectPath from '@/shared/mixins/redirect-path';
import projectMixins from '@/shared/mixins/projectMixins';

export default {
  components: {
    Contact,
    DetailSection,
    StepButton
  },

  mixins: [redirectPath, projectMixins],

  props: {
    content: Object,
    step: Number
  },

  data() {
    return {
      disableCard: false,
      // showFooterButton: true,
      query: this.$route.query,
      nextPageParams: {},
      projectId: this.$route.params.id,
      projectStatus: null,
      providedStatus: null,
      stepsResponse: {
        curStep: '0',
      },
      stepUrlParams: {},
      isLoading: false,
      selectedContactId: null,
      stepConfiguration: {},
      nav: 'cs',
    };
  },

  created() {
    const project_id = this.$route.params.id;
    if(project_id) {
      this.isLoading = true;
      this.$store.dispatch('fulfill_integrate/get_project_selected', { project_id })
      .then((response) => {
        if(this.locked(response.project) || this.isNotUserProject(response.project)) {
          this.$router.push('/applications');
          return
        } else {
          if (!this.queryStepNumber) { this.nav = 'ns'; }
          this.navigateNextStep(this.nav, true);
        }
      })
      .catch((error) => {
        this.isVisible = false;
        console.log(error);
      })
    }

    window.addEventListener('popstate', this.handlePopState);
  },

  computed: {
    queryStepNumber() {
      return this.$route.query.cs;
    },

    currentStepId() {
      return this.$route.query.cn;
    },

    queryStepType() {
      return this.$route.query.type;
    },

    queryHideInst() {
      return this.$route.query.hideinst === 'true';
    },

    queryHideTitle() {
      return this.$route.query.hidetitle === 'true';
    },

    totalSteps() {
      return Number(this.stepsResponse.step_num) + Number(this.stepsResponse.steps_remaining) - 1;
    },

    stepTitle() {
      const title = this.queryHideTitle ? '' : `: ${this.stepConfiguration.title}`;
      return `Step ${this.stepsResponse.step_num} of ${this.totalSteps}${title}`;
    },

    showFooterButton() {
      return this.queryStepType === 'contact';
    },

    settings() {
      return this.$store.state.partners.currentPartner.settings;
    },

    fulfillSyncDetails() {
      return this.settings.fulfill_sync_details;
    },
  },

  methods: {
    navigateNextStep(nav = 'ns', onCreate = false) {
      this.isLoading = true;
      this.nav = nav;
      let updatedStepNumber = this.queryStepNumber || 0;

      if (onCreate && this.queryStepNumber && this.stepsResponse.step_url) { return; }

      if (onCreate && updatedStepNumber > 0){
        updatedStepNumber -= 1;
      }

      const params = {
        cn: this.currentStepId,
        curStep: updatedStepNumber,
        nav: nav,
        projectId: this.projectId,
        onCreate: onCreate,
      }
      this.getPortalStepsAndConfigurations(params, true);
    },

    getComponent2(type) {
      switch (type) {
        case 'contact':
          return 'Contact';
        case 'prq':
          return 'DetailSection';
        case 'attachment':
          return 'DetailSection';
        default:
          return 'Contact';
      }
    },

    storeSelectedContact(id) {
      this.selectedContactId = id;
    },

    async saveContact(step) {
      const project_id = this.$route.params.id;
      this.$store.dispatch('fulfill_integrate/get_project_selected', { project_id })
        .then((response) => {
          if(this.locked(response.project)) {
            this.$notify({
              group: 'notice',
              text: 'This project is locked in the portal.',
            });
            this.$router.push('/applications');
            return
          }
        })
        .catch((error) => {
          this.isVisible = false;
          console.log(error);
        });
      if (this.selectedContactId) {
        this.isLoading = true;

        await this.$store.dispatch('fulfill_integrate/updateProjectPrimaryContact', {
          project_id: this.projectId,
          contact_id: this.selectedContactId,
        }).then((response) => {
          this.isLoading = false;
          this.$notify({
            group: 'notice',
            text: response.message,
          });

          if (step === 'nextStep') {
            this.navigateNextStep();
          } else if (step === 'linkStep') {
            this.redirectPath(this.stepsResponse.add_btn_url);
          }
        });
      } else {
        this.$notify({
          group: 'notice',
          text: 'Please select a contact before proceeding to the next step.',
          duration: 5000,
        });
      }
    },

    handlePopState() {
      this.isLoading = true;

      const params = {
        cn: this.currentStepId,
        curStep: Number(this.queryStepNumber),
        nav: 'cs',
        projectId: this.projectId,
      }
      this.getPortalStepsAndConfigurations(params);
    },

    getPortalStepsAndConfigurations(params, redirectPath) {
      this.$store.dispatch('fulfill_integrate/getPortalSteps', params).then((response) => {
          this.stepsResponse = response;
          this.projectStatus = response.project_status;
          const updatedUrl = this.stepsResponse.step_url.replaceAll('$PID$', this.projectId);
          if (this.$route.fullPath !== updatedUrl) {
            if (response.steps_remaining === '0') {
              this.$notify({
                group: 'notice',
                text: this.fulfillSyncDetails.messages.finish_steps,
                duration: 5000,
              });
            }

            if (redirectPath) {
              this.redirectPath(updatedUrl);
            }
          }
        }).catch((error) => {
          console.log(error);
          this.$router.push('/applications');
          return
        }).finally(() => {
          this.$store.dispatch('fulfill_integrate/getPageConfiguration', {
            task_name: this.$route.query.type == "prq" ? "response" : this.$route.query.type,
            project_id: this.$route.params.id,
            cur_step_name: this.stepsResponse.id
          })
          .then((response) => {
            this.stepConfiguration = response;
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
        });
    },
  }
}
</script>

<style scoped>
@media (max-width: 768px) {
  .mr-4, .ml-4, .mb-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
    margin-bottom: 1rem !important;
  }
}

#application-wrapper .content-box {
  margin: auto;
  overflow: visible;
}
</style>
