<template>
  <div :class="['content-box', { 'no-card': disableCard }]">
    <div :class="['card-body', { 'no-card': disableCard }]">
      <span v-if="group.title"  class="egx-partner-color">
        <h3 v-html="group.title"/>
      </span>
      <div v-for="item in sortedGroupFields" :key="item.field" class="row">
        <template v-if="item.name === 'BLANK'">
          <div class="col-12 mb-2">
            <span>&nbsp;</span>
          </div>
        </template>
        <template v-else>
          <div class="col-4 mb-2" v-if="item.label">
            <span style="font-weight: 700; color: #495057;">{{ item.label }}</span>
          </div>
          <div class="col-8 d-flex align-items-center" v-if="item.label">
            <span class="value-text">
              <template v-if="isDateField(item)">
                {{ formatDate(item.value) }}
              </template>
              <template v-else-if="isNumeric(item.value)">
                ${{ item.value }}
              </template>
              <template v-else-if="isBoolean(item.value)">
                <input type="checkbox" :checked="item.value === 'true'" disabled />
              </template>
              <template v-else>
                {{ item.value }}
              </template>
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disableCard: false,
    };
  },
  computed: {
    sortedGroupFields() {
      return this.group.fields.sort((a, b) => a.sort_order - b.sort_order);
    },
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return '';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    isDateField(item) {
      return (item.label && item.label.toLowerCase().includes('date')) || 
             (item.name && item.name.toLowerCase().includes('date'));
    },
    isNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
    isBoolean(value) {
      return value === 'true' || value === 'false';
    }
  }
}
</script>

<style scoped>
.value-text {
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
}
</style>
