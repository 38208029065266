import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

/* eslint-disable import/no-cycle */
import partnersModule from './modules/partners';
import usersModule from './modules/users';
import authModule from './modules/auth';
import genericItems from './modules/generic_items';
import adminsModule from './modules/admins';
import buildingModule from './modules/buildings';
import subsidyModule from './modules/subsidies';
import offerModule from './modules/offers';
import energyAdvisorAssignmentsModule from './modules/energy_advisor_assignments';
import documentsModule from './modules/documents';
import commentsModule from './modules/comments';
import virtualAuditsModule from './modules/virtual_audits';
import localeModule from './modules/locale';
import schedulePlanModule from './modules/schedule_plan';
import schedulePlanTimeslotsModule from './modules/schedule_plan_timeslot';
import contacts from './modules/contacts';
import fulfillIntegrateModule from './modules/fulfill_integrate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: authModule,
    partners: partnersModule,
    users: usersModule,
    generic_items: genericItems,
    admins: adminsModule,
    buildings: buildingModule,
    subsidies: subsidyModule,
    offers: offerModule,
    energyAdvisorAssignments: energyAdvisorAssignmentsModule,
    documents: documentsModule,
    comments: commentsModule,
    virtual_audits: virtualAuditsModule,
    locale: localeModule,
    schedulePlans: schedulePlanModule,
    schedulePlanTimeslots: schedulePlanTimeslotsModule,
    contacts: contacts,
    fulfill_integrate: fulfillIntegrateModule,
  },
  plugins: [createPersistedState()],
});
