<template>
  <form class="sign-up-form">
    <div class="input-group-lg">
      <ValidationObserver
        tag="form"
        @submit.prevent="updateUser()"
        ref="userEdit"
      >
        <form-field
          :formFields="fields"
          :show="true"
          :model="data.updateUser"
          :id-prefix="idPrefix"
        />

        <div class="form-check col-12 pr-0" v-show="settings.consent.show" v-if="settings.consent">
          <input
            class="form-check-input" type="checkbox" value=""
            v-model="data.updateUser.consent"
            id="user-consent"
          >
          <label
            class="form-check-label"
            for="user-consent"
            :class="settings.consent.class"
            :style="settings.consent.style"
            v-html="settings.consent.text"
          />
        </div>

        <div class="form-check col-12">
          <input
            class="form-check-input" type="checkbox"
            v-model="data.updateUser.subscribed"
            :class="settings.marketing.class"
            id="user-subscribed"
          >
          <label class="form-check-label" for="user-subscribed">
            {{ settings.marketing.text }}
          </label>
        </div>

        <button
          type="submit"
          class="btn btn-lg d-flex ml-auto mr-auto mt-3"
          :class="settings.attributes.button.class"
        >
          {{ settings.attributes.button.text }}
        </button>
      </ValidationObserver>
    </div>
  </form>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import FormField from './form/FormField.vue';
import router from '../router/index';

export default {
  name: 'UserFormFields',

  components: {
    FormField,
    ValidationObserver,
  },

  data() {
    return {
      idPrefix: 'user-complete-profile-page',
      showSuccessAlert: false,
      showErrorAlert: false,
      errorAlertMsg: '',

      data: {
        updateUser: {

        },
      },
    };
  },

  mounted() {
    this.updateUserData();
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    settings() {
      return this.partner.settings.sections.user_edit_page;
    },
    fields() {
      return this.settings.fields;
    },

    isFulFillSync() {
      return this.$store.state.users.currentUser.extra_info?.is_fulfill_sync;
    }
  },

  methods: {
    updateUser() {
      let userdata = this.data.updateUser;
      if (this.settings.consent && !this.data.updateUser.consent) {
        this.$notify({
          group: 'notice',
          text: 'You must agree to the terms and conditions to proceed.',
        });
      } else {
        this.$refs.userEdit.validate()
        .then((isValid) => {
          if (isValid) {
            this.$store.dispatch('users/put', userdata)
              .then(() => {
                this.$notify({
                  group: 'notice',
                  text: 'Profile updated.',
                });
                this.$emit('update-success');
              })
              .catch(() => {
                this.$notify({
                  group: 'notice',
                  text: 'Unable to proceed. Please try again.',
                });
              });
          } else {
            this.$notify({
              group: 'notice',
              text: 'Fill in all required fields.',
            });
          }
        });
      }
    },

    updateUserData() {
      this.data.updateUser = {
        ...this.$store.state.users.currentUser,
        ...this.$store.state.users.currentUser.extra_info,
      };
    },

    redirectBack(name) {
      router.push({ name });
    },
  },
};
</script>
