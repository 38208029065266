// import project icons separately to improve readability for main.js
import { library } from '@fortawesome/fontawesome-svg-core';

// import solid icons
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBolt,
  faCircle,
  faCircleNotch,
  faDollarSign,
  faHome,
  faPlus,
  faQuestion,
  faStar,
  faTimes,
  faTint,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';

// import light icons
import {
  faCheckCircle as falCheckCircle,
  faChevronDown as falChevronDown,
  faClipboardList as falClipboardList,
  faExclamationSquare as falExclamationSquare,
  faHouse as falHouse,
  faUser as falUser,
} from '@fortawesome/pro-light-svg-icons';

import { faFile as fasdtFile  } from "@fortawesome/sharp-duotone-thin-svg-icons";

// add imported icons to library
library.add(
  faStar,
  faDollarSign,
  faTint,
  faPlus,
  faTimes,
  faArrowDown,
  faBolt,
  faHome,
  faQuestion,
  faCircleNotch,
  faArrowLeft,
  faArrowRight,
  faCircle,
  falCheckCircle,
  falClipboardList,
  falUser,
  falExclamationSquare,
  falChevronDown,
  falHouse,
  fasdtFile
);
