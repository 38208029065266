<template>
  <div class="container">
    <h1
      class="d-flex justify-content-center mb-3"
      :class="settings.title.class"
      :style="settings.title.style"
    >
      {{ settings.title.text }}
    </h1>
    <span
      class="d-flex justify-content-center mb-5"
      :class="settings.description.class"
      :style="settings.description.style"
    >
      {{ settings.description.text }}
    </span>
    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(sendPasswordUpdateRequest)">
        <form-field
          :formFields="settings.sub_sections.password_reset.fields"
          :show="true"
          :model="credentials"
          :id-prefix="idPrefix"
        />

        <button
          class="d-flex ml-auto mr-auto mt-3 btn btn-egx-white"
          :class="settings.button.class"
          :style="settings.button.style"
          type="submit"
        >
          {{ settings.button.text }}
        </button>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
// eslint-disable-next-line import/no-cycle
import router from '../router/index';
import FormField from './form/FormField.vue';

export default {
  name: 'ResetPassword',

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },

  components: {
    FormField,
    ValidationObserver,
  },

  data() {
    return {
      idPrefix: 'password-reset',

      credentials: {
        password: '',
        password_confirmation: '',
        reset_password_token: this.$route.query.t,
      },
    };
  },

  methods: {
    sendPasswordUpdateRequest() {
      this.$store.dispatch('auth/updatePassword', this.credentials)
        .then((response) => {
          this.$notify({
            group: 'notice',
            text: this.settings.notice_success,
          });
          if (response && response.token) {
            router.push({ name: 'EmailVerification', query: {token: response.token, first_access: true } });
          }
          else {
            router.push({ name: this.settings.next_page });
          }

        })
        .catch((error) => {
          const message = error.response.data.errors[0].title
            || 'There was an error with your request, Please try again in a few minutes';

          this.$notify({
            group: 'notice',
            text: message,
          });
        });
    },
  },
};
</script>
