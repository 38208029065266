// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,
  state: {
    currentUser: {},
  },

  mutations: {
    set(state, data) {
      state.currentUser = data;
    },

    setExtraInfo(state, data) {
      state.currentUser.extra_info = data;
    },

    setCsvBuildingId(state) {
      state.currentUser.extra_info.user_csv_import_building_id = null;
    },
  },

  actions: {
    post({ commit }, data) {
      const payload = { user: data.user_data };
      if (data.extra_info) {
        payload.extra_info = data.extra_info;
      }
      return ajax.post('/api/v1/auth/registrations', payload)
        .then((response) => {
          if (data.email_verification) {
            return response.data;
          }
          commit('set', response.data.data.attributes);
          commit('auth/setCSRF', response.data.meta.csrf, { root: true });
        });
    },

    get({ commit }) {
      return ajax.get('/api/v1/users')
        .then((response) => {
          commit('set', response.data.data.attributes);
          commit('auth/setCSRF', response.data.meta.csrf, { root: true });
        });
    },

    put({ commit }, data) {
      return ajax.put(`/api/v1/users/${this.state.users.currentUser.id}`, { user: data })
        .then((response) => {
          commit('set', response.data.data.attributes);
          commit('auth/setCSRF', response.data.meta.csrf, { root: true });
        }).catch((error) => Promise.reject(error));
    },

    clean({ commit }) {
      commit('set', {});
    },

    changePassword(credentials) {
      return ajax.post('/api/v1/users/change_password', credentials)
        .catch((error) => Promise.reject(error));
    },

    storePage({ commit }, data) {
      const { currentUser } = this.state.users;
      currentUser.extra_info.current_page = data;

      return ajax.put(`/api/v1/users/extra_info/${currentUser.id}`, { data: { key: 'current_page', value: currentUser.extra_info.current_page } })
        .then((response) => {
          commit('setExtraInfo', response.data);
        })
        .catch((error) => Promise.reject(error));
    },

    adminUserSignUp({ commit }, data) {
      return ajax.post('/api/v1/auth/admin_user_sign_up', { user: data })
        .then((response) => {
          commit('set', response.data.data.attributes);
          commit('auth/setCSRF', response.data.meta.csrf, { root: true });
        });
    },

    updateCsvBuildingId({ commit }) {
      const { currentUser } = this.state.users;
      if (currentUser.extra_info.user_csv_import_building_id) {
        commit('setCsvBuildingId');
      }
    },

    accountVerification({ commit }, data) {
      return ajax.post('/api/v1/users/account_verification', { ...data })
        .then((response) => {
          return response.data;
        });
    },

    searchAndFetchAccountDetails({ commit }) {
      return ajax.get('api/v1/users/account_verification/search_and_sync_account', {})
        .then((response) => {
          return response.data;
        }).catch((error) => Promise.reject(error));
    },

    fetchSiteFields() {
      return ajax.get('api/v1/users/fetch_sites_fields', {})
        .then((response) => {
          return response.data;
        }).catch((error) => Promise.reject(error));
    }
  },
};
