<template>
  <div class="dialog" v-if="show" @click.self="close()">
    <div class="dialog-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  }
};
</script>

<style scoped lang="scss">
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog-content {
    background-color: white;
    padding: 20px;
    width: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    border-radius: 10px;
  }
}
</style>
