<template>
  <div class="container user-edit mt-5">
    <loading-screen v-show="isLoading" :content="loadingScreenContent" />
    <div v-show="!isLoading">
      <div class="content-box">
        <div class="mb-3 text-center" v-if="fulfill_sync_details && !detailVerificationStep && settings.attributes.title4">
          <h1
            class="text-center"
            :class="settings.attributes.title4.class"
            :style="{ ...settings.attributes.title4.style, color: partnerColor }"
          >
            {{ settings.attributes.title4.text }}
          </h1>
          <span
            :class="settings.attributes.subtitle4.class"
            :style="settings.attributes.subtitle4.style"
            v-if="fulfill_sync_details"
          >
            {{ settings.attributes.subtitle4.text }}
          </span>
        </div>
        <div class="input-group-lg d-flex justify-content-center mb-5" v-if="fulfill_sync_details && !detailVerificationStep">
          <span
            class="form-control readonly-input mb-3 d-flex "
            style="height: 48px; align-items: center;"
            id="user-email"
            >
            {{ currentUser.email }}
          </span>
        </div>
        <div class="mb-3 text-center">
          <h1
            class="text-center"
            :class="selectedTitle.class"
            :style="{ ...selectedTitle.style, color: fulfill_sync_details ? partnerColor : '' }"
          >
            {{ selectedTitle.text }}
          </h1>
          <span
            :class="selectedSubTitle.class"
            :style="selectedSubTitle.style"
            v-if="fulfill_sync_details"
          >
            {{ selectedSubTitle.text }}
          </span>
          <h4
            class="text-center"
            :class="selectedSubTitle.class"
            :style="selectedSubTitle.style"
            v-else
          >
            {{ selectedSubTitle.text }}
          </h4>
        </div>
        <user-form-fields
          @update-success="redirectToBuildingLists()"
          :isFulFillSync="isFulFillSync"
          :detailVerificationStep="detailVerificationStep"
          :isLoading="isLoading"
          :conditionalDisplay="conditionalDisplay"
          @loadingScreen="loadingScreen"
        />
      </div>
      <div class="content-box" v-if="fulfill_sync_details && !detailVerificationStep" v-show="contactCount > 0">
        <div class="card-body p-3">
          <h1
            class="text-center"
            :class="selectedTitle.class"
            :style="{ ...selectedTitle.style, color: fulfill_sync_details ? partnerColor : '' }"
          >
            Your Contacts
          </h1>
          <div class="mb-2 pb-4 pt-2">
            <span
              :class="selectedSubTitle.class"
              :style="selectedSubTitle.style"
              v-if="fulfill_sync_details"
            >
              Please select an existing Contact to click edit. Click '<b>Edit</b>' to modify its details, then click '<b>Update</b>'
              to save any changes or click '<b>Cancel</b>' to discard them. If the contact is not available, you can create a new one by clicking '<b>Add New</b>'.
            </span>
          </div>
          <contact
            :section="'user-profile'"
            @total-contacts="setContactNumber"
            :isUserEditPage="true"
            :userSelectedContactId="currentUser.extra_info?.selected_contact_id || ''"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '../../router/index';
import UserFormFields from '../../components/UserFormFields.vue';
import LoadingScreen from "@/components/LoadingScreen.vue";
import Contact from '../../components/Contact.vue';

export default {
  name: 'UserEdit',

  components: {
    LoadingScreen,
    UserFormFields,
    Contact,
  },

  data() {
    return {
      selectedTitle: '',
      selectedSubTitle: '',
      isLoading: false,
      contactCount: 0,
      conditionalDisplay: false,
    }
  },

  created() {
    if(this.emailVerificationStep){
      router.push({ name: 'fetch-account-details' });
    } else if(this.detailVerificationStep) {
      this.selectedTitle = this.settings.attributes.title3;
      this.selectedSubTitle = this.settings.attributes.subtitle3;
      this.conditionalDisplay = true;
    } else if(this.isFulFillSync) {
      this.selectedTitle = this.settings.attributes.title2;
      this.selectedSubTitle = this.settings.attributes.subtitle2;
      if(!this.isSynced) { this.conditionalDisplay = true; }
    } else {
      this.selectedTitle = this.settings.attributes.title;
      this.selectedSubTitle = this.settings.attributes.subtitle;
    }
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    settings() {
      return this.partner.settings.sections.user_edit_page;
    },

    isFulFillSync() {
      return this.$store.state.users.currentUser.extra_info?.is_fulfill_sync;
    },

    fulfill_sync_details() {
      return this.partner.settings.fulfill_sync_details && this.partner.settings.fulfill_sync_details.allowed;
    },

    emailVerificationStep() {
      return this.$store.state.users.currentUser.extra_info?.verification_step === 'email_search';
    },

    detailVerificationStep() {
      return this.$store.state.users.currentUser.extra_info?.verification_step === 'detail_search';
    },

    isSynced() {
      const syncedId = this.$store.state.users.currentUser.partner_account_id;
      return syncedId && syncedId.length > 1;
    },

    partnerColor() {
      const el = document.createElement('span');
      el.className = 'egx-partner-color';
      document.body.appendChild(el);
      const color = this.partner.settings.sections.custom_css["--egx-partner-color"]
      document.body.removeChild(el);
      return color;
    },

    loadingScreenContent() {
      return {
        title: {
          text: 'Updating account details',
          class: ['egx-partner-color'],
          style: 'color: var(--egx-partner-color)',
        },
        description: {
          text: 'Please wait while we update your account details.'
        },
        spinner:{
          class: ['egx-partner-color']
        },
        cta: {
          text: 'If this page doesn\'t refresh, please try again.',
          class: ['egx-partner-color'],
          path_name: 'buildings'
        }
      }
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
  },

  methods: {
    redirectToBuildingLists() {
      this.isLoading = false;
      this.$store.dispatch('buildings/setNew').then(() => {
        if(this.$store.state.users.currentUser.extra_info.programId) {
            router.push({ name: 'applicationsNew', query: { programId: this.$store.state.users.currentUser.extra_info.programId } });
          } else {
            router.push({ name: 'buildings-list' });
          }
      });
    },

    loadingScreen(val){
      this.isLoading = val;
    },

    setContactNumber(count){
      this.contactCount = count;
    }
  },
};
</script>

<style scoped>
.readonly-input {
  display: block;
  width: 37.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0 !important;
  appearance: none;
}
</style>
