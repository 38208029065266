<template>
  <div class="row" v-if="show">
    <div
      class="form-group input-group-lg"
      :class="columnMdSize(field.column_size)"
      v-for="(field, index) in allFormFields"
      :key="index"
      v-show="!field.hidden"
    >
<!-- <form-label-field :id-prefix="idPrefix" :field="field">{{ field.label }}</form-label-field>-->

      <form-input-field
        :id-prefix="idPrefix"
        :field="field"
        :model="model"
        :isCopyAddress="isCopyAddress"
        v-if="isInputType(field.type) && ((field.isSignupIntegrated && enableSignupIntegrated) || !field.isSignupIntegrated)"
      />

      <form-select-field
        :id-prefix="idPrefix"
        :field="field"
        :model="model"
        :isCopyAddress="isCopyAddress"
        v-else-if="field.type === 'select'"
      />

      <form-fieldset-field
        :id-prefix="idPrefix"
        :field="field"
        :model="model"
        :isCopyAddress="isCopyAddress"
        v-else-if="field.type === 'radio'"
      />

<!--      <form-text-area-field :id-prefix="idPrefix" :field="field" :model="model"-->
<!--                            :store-configuration="storeConfiguration"-->
<!--                            v-else-if="field.type === 'text-area'" />-->

<!--      <form-image-upload-field :id-prefix="idPrefix" :field="field" :model="model"-->
<!--                               :store-configuration="storeConfiguration"-->
<!--                               v-else-if="field.type === 'image-upload'"/>-->
    </div>
  </div>
</template>

<script>

// import FormLabelField from './LabelField.vue';
import FormInputField from './InputField.vue';
import FormSelectField from './SelectField.vue';
import FormFieldsetField from './FieldsetField.vue';
// import FormImageUploadField from './ImageUploadField.vue';
// import FormTextAreaField from './TextAreaField.vue';

export default {
  name: 'FormField',

  props: {
    formFields: Array,
    show: Boolean,
    model: Object,
    idPrefix: String,
    isCopyAddress: Boolean,
    enableSignupIntegrated: Boolean,
    isFulFillSyncAllowed: Boolean,

    // storeConfiguration: {
    //   type: Object,
    //   required: true,
    // },
  },

  data() {
    return {
      allFormFields: this.formFields,
      sitesFields: [],
    }
  },

  components: {
    // FormLabelField,
    FormInputField,
    FormSelectField,
    FormFieldsetField,
    // FormImageUploadField,
    // FormTextAreaField,
  },

  mounted() {
    let hasUtilityService = this.allFormFields.find(field => field.name === 'Utility_Service_Provider__c');

    if (this.isFulFillSyncAllowed && hasUtilityService) {
      this.$store.dispatch('users/fetchSiteFields').then((response) => {
        this.sitesFields = response.data;
        const utilityField = this.sitesFields.find(field => field.name === 'Utility_Service_Provider__c');

        if (utilityField) {
          const isRequired = utilityField.required === 'true';
          hasUtilityService.placeholder = `${utilityField.label}${isRequired ? '*' : ''}`;
          hasUtilityService.label = `${utilityField.label}${isRequired ? '*' : ''}`;
          hasUtilityService.validations = {
            required: isRequired,
          };
          hasUtilityService.options = utilityField.picklist_values.reduce((acc, value) => {
            acc[value.value] = value.label;
            return acc;
          }, {});
        }
      });
    }
  },

  methods: {
    isInputType(type) {
      const validTypes = ['text', 'number', 'email', 'password'];

      return validTypes.includes(type);
    },

    columnMdSize(columnSize) {
      if (columnSize === '6') {
        return 'col-md-6';
      }

      return 'col-md-12';
    },
  },

};

</script>
