<template>
<div :class="['content-box', { 'no-card': disableCard }]">
  <div class="m-2 pl-3 text-center" v-show="isLoading">
    <font-awesome-icon
      :icon="['fa', 'circle-notch']"
      class="fa-spin m-auto spinner fa-5x egx-partner-color"
    />
  </div>
  <div v-if="(applications?.length || 0) < 1" v-show="!isLoading">
    <p
      v-if="fulfill_sync_details.pages?.applications?.no_content"
      :class="fulfill_sync_details.pages.applications.no_content.class"
      :style="fulfill_sync_details.pages.applications.no_content.style"
      v-html="fulfill_sync_details.pages.applications.no_content.text"
    />
  </div>
  <div :class="['card-body', { 'no-card': disableCard }]" v-for="(application, index) in applications" v-else>
    <div class="row">
      <div class="col-lg-8">
        <div>
          <span class="egx-partner-color">
            <h4>{{ getProgramName(application.program_id) || application.name }}</h4>
          </span>
        </div>
        <div>
          <b>Application Id: </b>
          <span><router-link :to="`/applications/${application.id}`" class="egx-partner-color">{{ application.id }}</router-link></span>
        </div>
        <div>
          <b>Status: </b>
          <span v-html="sanitizeHTML(application.custom_status_description || application.status)" />
        </div>
        <div>
          <p>{{ application.description }}</p>
        </div>
        <div class="mt-4">
          <b>Last Updated: </b>
          <span>{{ application.custom_status_date || formatDate(application.last_modified_ts) }}</span>
        </div>
      </div>
      <div class="col-lg-4 d-flex flex-column justify-content-center align-items-center">
        <button class="btn btn-egx  mb-2" style="height: 3rem; width: 10rem;" @click="viewApplication(application.id)" v-if="settings.display_applications_view_button">View</button>
        <button class="btn btn-warning" style="height: 3rem; width: 10rem;" @click="updateApplication(application.id)" v-if="!locked(application)">Update</button>
      </div>
    </div>
    <hr v-if="index < applications.length - 1" />
  </div>
</div>
</template>

<script>
import DOMPurify from 'dompurify';
import projectMixins from '@/shared/mixins/projectMixins';

export default {
  components: {
  },
  mixins: [projectMixins],
  data() {
    return {
      applications: [],
      programs: [],
      disableCard: false,
      isLoading: false,
    };
  },

  created() {
    this.isLoading = true;
    this.$store.dispatch('fulfill_integrate/getProjects').then(() => {
      this.applications = this.$store.state.fulfill_integrate.projects;
      console.log(this.applications)
      this.programs = this.$store.state.fulfill_integrate.programs;
      this.$emit('number-of-applications', (this.applications?.length || 0));
      this.isLoading = false;
    }).catch((error) => {
      console.log(error.message);
      this.$notify({
        group: 'notice',
        text: error.message,
      });
    });
  },
  methods: {
    updateApplication(id) {
      this.$router.push(`/applications/${id}/edit?step=1`);
    },
    viewApplication(id) {
      this.$router.push(`/applications/${id}`);
    },
    formatDate(dateString) {
      return new Date(dateString).toUTCString();
    },
    sanitizeHTML(html) {
      return DOMPurify.sanitize(html);
    },
    getProgramName(programId) {
      const program = this.programs.find(prog => prog.id === programId);
      return program ? program.effective_program_name : null;
    },
  },
  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings;
    },

    fulfill_sync_details() {
      return this.settings.fulfill_sync_details;
    },
  }
}
</script>

<style scoped>
.card-body {
  margin-bottom: 10px;
}
hr {
  margin-bottom : -2rem;
}
.egx-partner-color {
  color: inherit;
}
</style>
