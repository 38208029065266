<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-12">
        <Applications />
      </div>
    </div>
  </div>
</template>

<script>
import Applications from '../components/Applications.vue';
import mockData from '../mockData';

export default {
  components: {
    Applications,
  },
  data() {
    return {
      disableCard: false,
    };
  },
}
</script>
