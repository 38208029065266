export default {
  methods: {
    redirectPath(path) {
      if (path.includes('https://')) {
        window.location.href = path;
      } else {
        this.$router.push(path);
      }
    },
  },
};
