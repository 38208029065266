export default {
  computed: {
    buildingPartnerIds() {
      return this.$store.state.buildings.allBuilding.map((b) => { return b.attributes.partner_building_id; });
    },
  },

  methods: {
    locked(project) {
      const projectLocked = this.checkLocked(project.locked);
      const lockedInPortal = this.checkLocked(project.locked_in_portal);
      const lockedInEducate = this.checkLocked(project.locked_in_educate);

      return (projectLocked || lockedInPortal || lockedInEducate);
    },

    checkLocked(value) {
      return ((value || 'no').toLowerCase().split(' ')[0] === 'yes');
    },

    isNotUserProject(project) {
      return !this.buildingPartnerIds.includes(project.site_id);
    }
  },
};
