/* eslint-disable import/no-cycle */
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    CSRF: '',
  },

  mutations: {
    setCSRF(state, token) {
      state.CSRF = token;
    },
  },

  actions: {
    signIn({ commit, dispatch }, credentials) {
      return ajax.post('/api/v1/auth/user_sessions', credentials)
        .then((response) => {
          commit('users/set', response.data.data.attributes, { root: true });
          commit('setCSRF', response.data.meta.csrf);

          // Update current Localize tester
          dispatch('locale/setCurrentTester', response.data.data.attributes.email, { root: true });
        })
        .catch((error) => {
          dispatch('signOut', { dispatch });
          return Promise.reject(error);
        });
    },

    tokenSignIn({ commit, dispatch }, params) {
      return ajax.get('/api/v1/auth/user_sessions/token_login', { params })
        .then((response) => {
          commit('users/set', response.data.data.attributes, { root: true });
          commit('setCSRF', response.data.meta.csrf);

          // Update current Localize tester
          dispatch('locale/setCurrentTester', response.data.data.attributes.email, { root: true });
        })
        .catch((error) => {
          dispatch('signOut', { dispatch });
          return Promise.reject(error);
        });
    },
    emailVerification({ commit, dispatch }, params) {
      return ajax.get('/api/v1/auth/user_sessions/email_verification', { params })
        .then((response) => {
          commit('users/set', response.data.data.attributes, { root: true });
          commit('setCSRF', response.data.meta.csrf);

          dispatch('locale/setCurrentTester', response.data.data.attributes.email, { root: true });
        })
        .catch((error) => {
          dispatch('signOut', { dispatch });
          return Promise.reject(error);
        });
    },

    resendEmailVerification({ commit, dispatch }, params) {
      return ajax.get('/api/v1/auth/user_sessions/resend_email_verification', { params })
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    checkConfirmation({ commit }, params) {
      return ajax.get('/api/v1/auth/user_sessions/check_confirmation', { params:  params })
        .then((response) => {
          if (response.data.confirmation_token) {
            return Promise.resolve(response.data.confirmation_token);
          } else {
            return Promise.resolve('User is confirmed');
          }
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    signOut({ dispatch }) {
      dispatch('clean');

      // /user_sessions/1 because the DELETE request requires an ID and we do not use it.
      return ajax.delete('/api/v1/auth/user_sessions/1');
    },

    clean({ commit, dispatch }) {
      commit('setCSRF', '');
      dispatch('users/clean', null, { root: true });
      dispatch('buildings/clean', null, { root: true });
      dispatch('generic_items/clean', null, { root: true });
      dispatch('admins/clean', null, { root: true });
      dispatch('virtual_audits/clean', null, { root: true });
      dispatch('subsidies/clean', null, { root: true });
      dispatch('offers/clean', null, { root: true });
    },

    // eslint-disable-next-line no-empty-pattern
    forgotPassword({}, params) {
      return ajax.post('/api/v1/auth/forgot_password', params)
        .catch((error) => Promise.reject(error));
    },

    updatePassword({ commit }, credentials) {
      return ajax.post('/api/v1/auth/user_update_password', credentials)
        .then((response) => {
          if (!response.data.token) {
            commit('users/set', response.data.data.attributes, { root: true });
            commit('setCSRF', response.data.meta.csrf);
          }
          else{
            return Promise.resolve(response.data);
          }
          
        })
        .catch((error) => {
          throw error;
        });
    },

    setNewToken({ commit }, token) {
      commit('setCSRF', token);
    },

    adminPermissions ({ commit, dispatch }) {
      return ajax.get('/api/v1/auth/admin_sessions/')
        .then((response) => {
          commit('admins/setPermission', response.data.fullPermissions, { root: true });
        })
        .catch((error) => {
          dispatch('signOut', { dispatch });
          return Promise.reject(error);
        });
    }
  },
};
